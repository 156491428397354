// src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';


const resources = {
  en: {
    translation: {
      ordersCalendar: "Order Calendar",
      orders: "Orders",
      planFoods: "Plan Foods",
      users: "Users",
      invalidDateInRenderDay:"Invalid date in renderDay:",
      unableToFetchOrders:"Unable to fetch Orders",
      selectedA:"Selected A:",
      selectedB:"Selected B:",
      doNotWant: "Do Not Want:",
      notSelectedDefaultA:"Not Selected (Default A):",
      totalA:"Total A:",
      totalAB:"Total A+B:",
      planFoodsCalendar:"Plan Foods Calendar",
      save: "Save",
      unableToUpdateMENU:"Unable to Update MENU",
      mENUUpdatedSuccessfully:"MENU Updated Successfully",
      unableToFetchCalendarMenu:"Unable to fetch Calendar Menu",
      addUserExcel: "Add Users Excel",
      name:"Name",
      unableToFetchStudentList:"Unable to fetch Student List",
      studentDeletedSuccessfully:"Student Deleted Successfully",
      unableToDeleteStudent:"Unable to Delete Student",
      inviteIsSentToStudentsSuccessfully:"Invite is sent to Students Successfully",
      unableToSendInvite:"Unable to Send Invite",
      studentDeactivatedSuccessfully:"Student Deactivated Successfully",
      unableToDeactivateStudent:"Unable to Deactivate Student",
      studentDisabledSuccessfully:"Student Disabled Successfully",
      unableToDisableStudent:"Unable to Disable Student",
      studentEnabledSuccessfully:"Student Enabled Successfully",
      unableToEnableStudent:"Unable to Enable Student",
      addUser: "Add User",
      newStudentCreatedSuccessfully:"New Student Created Successfully",
      unableToAddStudent:"Unable to Add Student",
      addStudent:"Add Student",
      invalidEmailAddress:"Invalid email address",
      parentEmailIsRequired:"Parent Email is required",
      OMNumberIsRequired:"OM Number is required",
      studentNameIsRequired:"Student name is required",
      parentEmail:"Parent Email",
      OMNumber:"OM number",
      studentName:"Student name",
      studentClass:"Student Class",
      sendInvitation:"Send Invitation",
      submit:"Submit",
      close: "Close",
      studentUpdatedSuccessfully:"Student Updated Successfully",
      unableToUpdateStudent:"Unable to Update Student",
      editUser:"Edit User",
      areYouSureYouWantTo:"Are you sure you want to",
      resendUsers:"Resend Users",
      resendUser:"Resend User",
      disableUsers:"Disable Users",
      disableUser:"Disable User",
      user:"User",
      areYouSureYouWantToDelete:"Are you sure you want to Delete",
      delete:"Delete",
      yes: "Yes",
      no: "No",
      resendInviteTo:"Resend Invite to",
      selected:"selected",
      deactivate:"Deactivate",
      enabled:"Enabled",
      disabled: "Disabled",
      password8Character:"Password must be 8 character length",
      passwordIsRequired:"Password is required!",
      emailIsRequired:"Email is required!",
      rememberMe:"Remember Me",
      password: "Password",
      login: "Login",
      loggedInSuccessful:"Logged in successful",
      selectClass: "Select Class",
      download: "Download",
      pleaseSelectAClassBeforeDownloading:"Please select a class before downloading",
      unableToDownloadQRCode:"Unable to Download QR code",
      downloadQRCodes:"Download QR Codes",
      downloadAllQRs:"Download All QRs",
      downloadQRByClass:"Download QR by Class",
      unableToGetClasses:"Unable to get Classes",
      unableToDownloadAllQRCode:"Unable to Download All QR code",
      searchClass:"Search class",
    }
  },
  hu: {
    translation: {
      searchClass:"Keresés az osztályban",
      unableToDownloadAllQRCode:"Nem lehet letölteni az összes QR-kódot",
      unableToGetClasses:"Nem sikerült megszerezni az osztályokat",
      downloadQRByClass:"Töltse le a QR-t osztályonként",
      downloadAllQRs:"Töltse le az összes QR-t",
      downloadQRCodes:"Töltse le a QR-kódokat",
      unableToDownloadQRCode:"Nem sikerült letölteni a QR-kódot",
      pleaseSelectAClassBeforeDownloading:"Kérjük, a letöltés előtt válasszon egy osztályt",
      download:"Letöltés",
      selectClass:"Válassza az Osztály lehetőséget",
      loggedInSuccessful:"Sikeres bejelentkezés",
      login: "Bejelentkezés",
      password: "Jelszó",
      rememberMe:"Emlékezz rám",
      emailIsRequired:"Az e-mail megadása kötelező!",
      passwordIsRequired:"A jelszó megadása kötelező!",
      password8Character:"A jelszónak 8 karakter hosszúnak kell lennie",
      disabled: "Letiltva",
      enabled: "Engedélyezve",
      deactivate:"Deaktiválás",
      selected: "kiválasztott",
      resendInviteTo:"Meghívó újbóli küldése",
      yes: "Igen",
      no:"Nem",
      delete: "Törlés",
      areYouSureYouWantToDelete:"Biztos benne, hogy törölni szeretné",
      user:"Felhasználó",
      disableUser:"Felhasználó letiltása",
      disableUsers:"Felhasználók letiltása",
      resendUser:"Felhasználó újraküldése",
      resendUsers:"Felhasználók újraküldése",
      areYouSureYouWantTo:"Biztos benne, hogy szeretné",
      editUser: "Felhasználó szerkesztés",
      unableToUpdateStudent:"A diák frissítése nem lehetséges",
      studentUpdatedSuccessfully:"A diák sikeresen frissítve",
      close:"Bezár",
      submit:"Küldés",
      sendInvitation:"Meghívó küldése",
      studentName:"Diák neve",
      studentClass:"Diák osztálya",
      OMNumber:"OM szám",
      parentEmail:"Szülő e-mail",
      studentNameIsRequired:"A diák neve kötelező",
      OMNumberIsRequired:"OM szám kötelező",
      parentEmailIsRequired:"A szülő e-mail címe kötelező",
      invalidEmailAddress:"Érvénytelen e-mail cím",
      addStudent:"Diák hozzáadása",
      unableToAddStudent:"A diák hozzáadása nem lehetsége",
      newStudentCreatedSuccessfully:"Új diák sikeresen létrehozva",
      addUser: "Felhasználó hozzáadása",
      unableToEnableStudent:"A diák engedélyezése nem lehetséges",
      studentEnabledSuccessfully:"A diák sikeresen engedélyezve",
      unableToDisableStudent:"A diák letiltása nem lehetséges",
      studentDisabledSuccessfully:"A diák sikeresen letiltva",
      unableToDeactivateStudent:"A diák inaktiválása nem lehetséges",
      studentDeactivatedSuccessfully:"A diák sikeresen inaktiválva",
      unableToSendInvite:"A meghívó elküldése nem lehetséges",
      inviteIsSentToStudentsSuccessfully:"A meghívó sikeresen elküldve a diákoknak",
      unableToDeleteStudent:"A diák törlése nem lehetséges",
      studentDeletedSuccessfully:"A diák sikeresen törölve",
      unableToFetchStudentList:"A diáklista lekérése nem lehetséges.",
      name: "Név",
      addUserExcel: "Felhasználók hozzáadása Excelben",
      unableToFetchCalendarMenu:"A Naptár Menü lekérése nem lehetséges",
      mENUUpdatedSuccessfully:"A MENÜ sikeresen frissítve",
      unableToUpdateMENU:"A MENÜ frissítése nem lehetséges",
      save: "Mentés",
      planFoodsCalendar:"Ételek tervezési naptára",
      totalAB:"Összesen A+B:",
      totalA:"Összesen A:",
      notSelectedDefaultA:"Nincs kiválasztva (Alapértelmezett: A):",
      doNotWant:"Nem akarom:",
      selectedA:"Kiválasztott A:",
      selectedB:"Kiválasztott B:",
      unableToFetchOrders:"Nem sikerült lekérni a rendeléseket",
      invalidDateInRenderDay:"Érvénytelen dátum a renderDay-ben:",
      users: "Felhasználók",
      planFoods: "Ételek tervezése",
      orders: "Megrendelések",
      ordersCalendar: "Rendelési naptár",
    }
  }
};

i18n
  .use(initReactI18next) // Passes i18n down to react-i18next
  .init({
    resources,
    lng: "hu", // Default language
    fallbackLng: "hu", // Fallback language if translation is missing
    interpolation: {
      escapeValue: false // React already does escaping
    }
  });

export default i18n;
