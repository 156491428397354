import { lazy } from "react";
import Loadable from "app/components/Loadable";

const PlanFoods = Loadable(lazy(() => import("./PlanFoods/PlanFoods")));
const Orders = Loadable(lazy(() => import("./Orders/Orders")));
// const Analytics2 = Loadable(lazy(() => import("./Analytics2")));
const Users = Loadable(lazy(() => import("./users/Users")));
const QRDownload = Loadable(lazy(() => import("./qrDowloads/qrDownload")));

const dashboardRoutes = [
  // { path: "dashboard/home", element: <Analytics2 /> },
  { path: "dashboard/orders", element: <Orders /> },
  { path: "dashboard/plan-foods", element: <PlanFoods /> },
  { path: "dashboard/user", element: <Users /> },
  { path: "dashboard/qr-download", element: <QRDownload /> },
];

export default dashboardRoutes;
