export const navigations = [
  // {
  //   name: "Home",
  //   path: "/dashboard/home",
  //   icon: "home",
  // },
  {
    name: "orders",
    path: "/dashboard/orders",
    icon: "receipt",
  },
  {
    name: "planFoods",
    path: "/dashboard/plan-foods",
    icon: "restaurant",
  },
  {
    name: "users",
    path: "/dashboard/user",
    icon: "person",
  },
  {
    name: "QR Download",
    path: "/dashboard/qr-download",
    icon: "QrCode",
  }
];
