import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { planFoodsUrl } from 'app/api/api_urls';
import axios from 'axios';

const initialState = {
  planFoods: [],
  loading: false,
  error: null,
};

export const fetchPlanFoods = createAsyncThunk('planFoods/fetchPlanFoods', async (query) => {
  const token = localStorage.getItem("access");
  const planFoodsMenuURL = `${planFoodsUrl}${query}`
  try {
    const response = await axios.get(planFoodsMenuURL, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  }  catch (error) {
      const data = error?.response?.data || error.message;
      const statusCode = error.response?.status;
      const _err = {
        message: data,
        statusCode,
      };
      throw _err;
  }
});


const planFoodSlice = createSlice({
  name: 'planFoods',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPlanFoods.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchPlanFoods.fulfilled, (state, action) => {
        state.loading = false;
        state.planFoods = action.payload;
      })
      .addCase(fetchPlanFoods.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const planFoodReducer = planFoodSlice.reducer;
