import AuthGuard from "./auth/AuthGuard";
import Redirect from "./auth/Redirect";
import DashboardLayout from "./components/layouts/Layout";
import dashboardRoutes from "./views/dashboard/DashboardRoutes";
import NotFound from "./views/sessions/NotFound";
import sessionRoutes from "./views/sessions/SessionRoutes";

const routes = [
  {
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      ...dashboardRoutes,
      ],
  },
  ...sessionRoutes,
  { path: "/", element: <Redirect /> },
  { path: "*", element: <NotFound /> },
];

export default routes;
