import { configureStore } from "@reduxjs/toolkit";
import { planFoodReducer } from "./slices/planFoodSlice";
import { studentReducer } from "./slices/studentSlice";
import { orderReducer } from "./slices/orderSlice";

const store = configureStore({
  reducer: {
   planFoods: planFoodReducer,
   orders: orderReducer,
   students: studentReducer
  },
  devTools: process.env.NODE_ENV !== "production",
});

export default store;
